export default {
    name: 'dataTable',

    props: {
        tableData : {
            type:Object
        }
    },
    data() {
        return {
          headers:[],
          rows:[]
        }
    },

    components: {
       
    },

    methods: {
      getData(data){
        this.headers = data.headers;
        this.rows = data.rows;
      }
    },


    mounted() {
       this.getData(this.tableData);
    },

   


}